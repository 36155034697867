import { ComponentType, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tab, Tabs } from '@mui/material'

import {
	TaskListType,
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { Box } from 'src/components'
import { useMyTasks } from 'src/hooks/useMyTasks'
import { RootState } from 'src/store/types'

import { SingleTaskContainer } from './single-task-container'
import { TaskList } from './task-list'

export const MobileSectionContainer: ComponentType = () => {
	const dispatch = useDispatch()
	const { selectedTaskId } = useMyTasks()
	const taskListType = useSelector(projectSelectors.getTaskListType)
	const tasks = useSelector(projectSelectors.getProjectTasks)
	const selectedTask = useSelector((state: RootState) =>
		projectSelectors.getSelectedTask(state, selectedTaskId),
	)
	const [isLoading, setIsLoading] = useState(false)

	const handleTaskTypeChange = (type: TaskListType) => {
		dispatch(projectActions.setTaskListType(type))
	}

	return (
		<Box flex={1}>
			<Box display="flex" mb={1}>
				<Tabs value={taskListType} onChange={(e, value) => handleTaskTypeChange(value)}>
					<Tab value={TaskListType.Active} label={`Active tasks (${tasks.length})`} />
					{/* <Tab
						value={TaskListType.ProjectTasks}
						label={`Project tasks (${masterTasks.length})`}
						disabled={!masterTasks.length}
					/> */}
				</Tabs>
			</Box>
			<Box
				flex={1}
				display="flex"
				flexDirection="column"
				bgcolor="white"
				borderRadius="10px"
				boxShadow="0px 1px 10px 0px rgba(0, 0, 0, 0.05)"
				p={1}
			>
				{selectedTask ? <SingleTaskContainer onTaskLoading={setIsLoading} /> : <TaskList />}
			</Box>
		</Box>
	)
}
