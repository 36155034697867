import React, { ComponentType, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ChevronDown } from 'src/assets/icons'
import { ROUTES, RouteId } from 'src/constants/routes'
import { selectors as userSelectors } from 'src/store/modules/user'
import { selectors as persistedConfigSelectors } from 'src/store/modules/persisted-config'

import { Box } from '../../../components/box'
import { MenuItem } from '../components/menu-item'
import { ProjectsMenuItem } from '../components/projects-menu-item'

type MainNavProps = {
	projectsPopover: HTMLButtonElement | null
	onProjectsClick: (event: React.MouseEvent<HTMLButtonElement>) => void
	onProjectsClose: () => void
	onRouteClick: (routeId: RouteId) => void
}

export const MainNav: ComponentType<MainNavProps> = ({
	onProjectsClick,
	onProjectsClose,
	projectsPopover,
	onRouteClick,
}) => {
	const userPermissions = useSelector(userSelectors.getPermissions)
	const recentlyViewedProjects = useSelector(persistedConfigSelectors.getRecentlyViewedProjects)

	const routes = useMemo(
		() =>
			ROUTES.filter((route) => {
				if (!userPermissions) return false
				return route.permissions.includes(userPermissions)
			}),
		[userPermissions],
	)

	const routesInAppBar = routes.filter(({ id }) => {
		const routesNotToDisplay = [
			RouteId.Blueprint,
			RouteId.Project,
			RouteId.Users,
			RouteId.Roles,
			RouteId.Tables,
			RouteId.Inbox,
			RouteId.Contacts,
		]

		routesNotToDisplay.push(RouteId.Blueprint)
		routesNotToDisplay.push(RouteId.Project)

		return !routesNotToDisplay.includes(id)
	})

	const handleMenuItemClick = (event: React.MouseEvent<HTMLButtonElement>, routeId: RouteId) => {
		if (routeId === RouteId.Projects && !!recentlyViewedProjects.length) {
			onProjectsClick(event)
			return
		}
		onRouteClick(routeId)
	}

	return (
		<Box flexGrow={1} display="flex">
			{routesInAppBar.map((route) => {
				const RouteIcon = route.icon
				const routeUrl = `/${route.id}`

				return (
					<Box key={route.id}>
						<MenuItem
							endIcon={
								route.id === RouteId.Projects && !!recentlyViewedProjects.length ? (
									<ChevronDown fill="#fff" />
								) : undefined
							}
							startIcon={RouteIcon ? <RouteIcon fill="#fff" width={18} /> : undefined}
							onMenuItemClick={handleMenuItemClick}
							routeId={route.id}
							routeUrl={routeUrl}
							routeName={route.label}
						/>
						{route.id === RouteId.Projects && (
							<ProjectsMenuItem
								routeId={route.id}
								projectsPopover={projectsPopover}
								onProjectsClick={onProjectsClick}
								onProjectsClose={onProjectsClose}
								onRouteClick={onRouteClick}
							/>
						)}
					</Box>
				)
			})}
		</Box>
	)
}
