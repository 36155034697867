import { ComponentType, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectors as notesSelectors } from 'src/store/modules/notes'
import { showSnackbar } from 'src/helpers/snackbarManager'

import TaskChat from '../my-tasks-v3/components/task-chat'
import { TaskDrawer } from '../projects-v3/project-detail/task-drawer'

export const InboxTaskChat: ComponentType = () => {
	const [taskDrawerId, setTaskDrawerId] = useState<string>()
	const selectedInboxItem = useSelector(notesSelectors.getSelectedInboxItem)

	const handleTaskNameClick = () => {
		setTaskDrawerId(selectedInboxItem?.task._id)
	}

	const handleRevertInstance = async () => {
		showSnackbar('Reverting instances can only be done in the project view', { variant: 'warning' })
	}

	return (
		<>
			<TaskDrawer
				taskId={taskDrawerId}
				onClose={() => setTaskDrawerId(undefined)}
				onRevertInstance={handleRevertInstance}
			/>
			<TaskChat
				task={selectedInboxItem?.task}
				project={selectedInboxItem?.project}
				onTaskNameClick={handleTaskNameClick}
			/>
		</>
	)
}
