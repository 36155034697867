import React, { ComponentType, useEffect, useState } from 'react'
import { ClientTypes, UserTypes } from '@cango-app/types'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import capitalize from 'lodash/capitalize'

import { Box, Text, Button, TextField, Select } from 'src/components'
import { actions as userActions, selectors as userSelectors } from 'src/store/modules/user'
import { selectors as roleSelectors, actions as roleActions } from 'src/store/modules/roles'
import type { RootState } from 'src/store/types'

type UserFormProps = {
	user?: ClientTypes.User & { assignments?: number }
	closeDrawer: () => void
	setShouldPromptDelete: (tf: boolean) => void
}

type UserFormValues = Omit<ClientTypes.User, '_id' | 'roles'> & { roles: string[] }

export const UserForm: ComponentType<UserFormProps> = ({
	user,
	closeDrawer,
	setShouldPromptDelete,
}) => {
	const dispatch = useDispatch()
	const roles = useSelector((state: RootState) => roleSelectors.getRolesForSelect(state, true))
	const allRolesLength = useSelector(roleSelectors.getRoles).length
	const users = useSelector(userSelectors.getAllUsers)
	const [isUpdating, setIsUpdating] = useState(false)

	const { handleSubmit, control } = useForm<UserFormValues>({
		defaultValues: {
			permissions: user?.permissions,
			name: user?.name,
			surname: user?.surname,
			roles: user?.roles || [],
			email: user?.email,
			phone: user?.phone,
		},
	})

	const validateEmailNotInUse = (inputtedEmailAddress: string): boolean => {
		const otherUsersWithEmail = users.find(({ email }) => email === inputtedEmailAddress)
		return !!user || !otherUsersWithEmail
	}

	const onSubmit = async (
		userData: Omit<ClientTypes.User, '_id' | 'roles'> & { roles: string[] },
	) => {
		setIsUpdating(true)
		if (user?._id) {
			await dispatch(userActions.updateUser({ userId: user._id, update: userData }))
		} else {
			await dispatch(userActions.createUser(userData))
		}
		setIsUpdating(false)
		closeDrawer()
	}

	const userHasAssignments = user?.assignments ? user.assignments > 0 : false

	useEffect(() => {
		if (!allRolesLength) {
			dispatch(roleActions.getRoles())
		}
	}, [])

	return (
		<Box width={400} p={3} role="presentation">
			<Text sx={{ mb: 3 }} variant="h4">
				{user ? user.name + ' ' + user.surname : 'New User'}
			</Text>

			<form>
				<Controller
					name={'permissions'}
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<Select
							error={!!error}
							label="What permission level is this user?"
							onChange={(event) => onChange(event.target.value)}
							value={value}
							options={Object.values(UserTypes.Permission).map((item) => ({
								_id: item,
								label: capitalize(item),
							}))}
							containerProps={{ mb: 3 }}
						/>
					)}
				/>
				<Box display={'flex'}>
					<Controller
						name={'name'}
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								error={!!error}
								required
								fullWidth
								onChange={onChange}
								value={value}
								label={'Name'}
								containerProps={{ mb: 3, mr: 1 }}
							/>
						)}
					/>
					<Controller
						name={'surname'}
						control={control}
						rules={{ required: true }}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<TextField
								error={!!error}
								onChange={onChange}
								value={value}
								required
								fullWidth
								label={'Surname'}
								containerProps={{ mb: 3, ml: 1 }}
							/>
						)}
					/>
				</Box>
				<Controller
					name={'roles'}
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<Select
							error={!!error}
							multiple
							multiline
							label="Which Role(s) does this User perform?"
							onChange={(event) => onChange(event.target.value)}
							value={value}
							options={roles}
							containerProps={{ mb: 3 }}
						/>
					)}
				/>
				<Controller
					name={'email'}
					control={control}
					rules={{
						required: true,
						validate: {
							checkEmail: (v) => validateEmailNotInUse(v) || 'Email address already in use',
						},
					}}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextField
							error={!!error}
							disabled={!!user}
							onChange={onChange}
							value={value}
							helperText={!!error && error.message}
							required
							fullWidth
							label={'Email address'}
							containerProps={{ mb: 3 }}
						/>
					)}
				/>
				<Controller
					name={'phone'}
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextField
							error={!!error}
							onChange={onChange}
							value={value}
							fullWidth
							label={'Phone number'}
							containerProps={{ mb: 3 }}
						/>
					)}
				/>
			</form>

			<Button variant="contained" onClick={handleSubmit(onSubmit)} isLoading={isUpdating}>
				{user ? 'Update' : 'Create'}
			</Button>
			{!!user && (
				<Button
					onClick={() => setShouldPromptDelete(true)}
					variant="outlined"
					color="error"
					disabled={userHasAssignments}
					sx={{ float: 'right' }}
				>
					{userHasAssignments ? 'User is assigned' : 'Delete'}
				</Button>
			)}
		</Box>
	)
}
