import React, { ComponentType, useEffect, useMemo, useState } from 'react'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import {
	DataGridPremium,
	GridCellParams,
	GridColDef,
	GridRenderCellParams,
	GridRowClassNameParams,
} from '@mui/x-data-grid-premium'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import { dataGridStandard } from 'src/helpers/ui'
import { Box } from 'src/components'
import { selectors as userSelectors } from 'src/store/modules/user'
import { getActionLabel, readableList } from 'src/helpers/labels'
import { SectionTitleBar } from 'src/modules/projects-v3/project-detail/section-tasks/title-bar'
import { ActionIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import ChipGroup from 'src/components/chip-group'
import { ProjectSection } from 'src/store/modules/projects-v3/selectors'
import { TaskTitleContent } from 'src/modules/my-tasks-v3/components/task-title'

import { assigneeLabel } from './assignee-label'

type SectionTasksProps = {
	section: ProjectSection
	onEditTask: (taskId: string) => void
}

export enum ColumnId {
	Name = 'name',
	Action = 'action',
	Options = 'completedOptions',
	Assignees = 'assignees',
	LatestNote = 'note',
}

type ProjectRowType = {
	id: string
	complete: boolean
	name: string
	assignees: string
	unassigned: boolean
	missingdate: boolean
	parent: string
	chain: V3ClientTypes.Project.Task['chain']
	iteration: number
	action: V3BlueprintTypes.ActionEnum
	when: V3ClientTypes.Project.Task['when']
	instanceName?: string
	numberOfUses?: number
	groupingParentName?: string
	groupingParentOptions?: string[]
	completedOptions?: string[]
	options?: string[]
	isFlagged?: boolean
	isBlockedType?: boolean
	__reorder__?: string
}

const columns: GridColDef<ProjectRowType>[] = [
	{
		headerName: '',
		field: ColumnId.Action,
		type: 'actions',
		renderCell: (params: GridRenderCellParams<ProjectRowType>) => (
			<Tooltip title={getActionLabel(params.row.action)}>
				<ActionIcon actionType={params.row.action} width={24} stroke={colors.neutral['80']} />
			</Tooltip>
		),
		maxWidth: 50,
	},
	{
		headerName: ColumnId.Name,
		field: ColumnId.Name,
		minWidth: 50,
		renderCell: (params: GridRenderCellParams<ProjectRowType>) => (
			<TaskTitleContent
				instanceName={params.row.instanceName}
				taskName={params.row.name}
				chain={params.row.chain}
				iteration={params.row.iteration}
				numberOfUses={params.row.numberOfUses}
				isFlagged={params.row.isFlagged}
			/>
		),
	},
	{
		headerName: 'Selection(s)',
		field: ColumnId.Options,
		type: 'actions',
		renderCell: (params: GridRenderCellParams<ProjectRowType>) => (
			<ChipGroup labels={params.row.completedOptions} />
		),
		align: 'left',
		headerAlign: 'left',
	},
	{
		headerName: 'Assignees',
		field: ColumnId.Assignees,
		type: 'string',
		maxWidth: 200,
	},
]

const getCellClassnames = (params: GridCellParams<ProjectRowType>) => {
	const classnames = classNames({
		'task-flagged-note':
			params.field === ColumnId.LatestNote && !!params.row.isFlagged && !!params.formattedValue,
		'cango-error-text':
			(params.field === 'assignee' && params.row.unassigned) ||
			(params.field === 'info' && params.row.missingdate),
	})

	return classnames
}

const getRowClassnames = (params: GridRowClassNameParams<ProjectRowType>) => {
	const classnames = classNames({
		'task-flagged': !!params.row.isFlagged,
		'task-last-visible': !!params.isLastVisible,
		'task-state-complete': !!params.row.complete,
		'task-state.outstanding': !params.row.complete,
	})
	return classnames
}

const ProjectSectionTasks: ComponentType<SectionTasksProps> = ({ section, onEditTask }) => {
	const mappedUsers = useSelector(userSelectors.getMappedUsers)

	const rows = section.tasks.map((row): ProjectRowType => {
		const filteredAssignees = row.assignees?.filter((_assignee) => !!_assignee.user) ?? []
		return {
			id: row._id,
			complete: !!row.lifecycle?.complete,
			name: row.name,
			instanceName: row.instance?.name,
			numberOfUses: row.isMultiUse ? row.instance?.instances.length : 0,
			action: row.actions.length ? row.actions[0].type : V3BlueprintTypes.ActionEnum.None,
			parent: row.parent?.name,
			chain: row.chain,
			iteration: row.iteration,
			completedOptions: row.lifecycle.completed_options
				? row.lifecycle.completed_options
						.filter((_option) => _option._id !== row.chain?.option_id)
						.map((option) => option.label)
						.filter(Boolean)
				: [],
			when: row.when,
			assignees: readableList(
				filteredAssignees.map(
					(_assignee) => assigneeLabel(mappedUsers.get(_assignee.user || '')) ?? '',
				),
			),
			unassigned: !row.assignees?.length,
			missingdate: row.when.type === V3BlueprintTypes.WhenEnum.Milestone && !row.when.date,
			isFlagged: !!row.isFlagged,
			__reorder__: row.name,
		}
	})
	const allTaksCompleted = useMemo(() => rows.every(({ complete }) => complete), [rows])
	const [expand, setExpand] = useState(!allTaksCompleted)

	useEffect(() => {
		if (allTaksCompleted) {
			setExpand(false)
		}
	}, [allTaksCompleted])

	const rowCount = section.tasks.length
	const shouldLazyLoad = !!rowCount && rowCount >= 12

	if (!section) {
		return null
	}

	return (
		<Box
			p={2}
			boxShadow="rgb(0 0 0 / 12%) 2px 4px 24px;"
			borderRadius="0 20px 20px 20px"
			mb={2}
			bgcolor="#fff"
		>
			<Accordion
				id={`${section.name}-${section._id}`}
				aria-controls={`${section.name}-table`}
				expanded={expand}
				onChange={() => {
					if (allTaksCompleted) {
						setExpand((currentValue) => !currentValue)
					}
				}}
				sx={{
					boxShadow: 'none',
					'.MuiAccordionSummary-root': {
						cursor: allTaksCompleted ? 'pointer' : 'default !important',
					},
				}}
			>
				<AccordionSummary>
					<SectionTitleBar sectionName={section.name} rowCount={rowCount} chain={section.chain} />
				</AccordionSummary>
				{
					<AccordionDetails>
						<Box
							height={shouldLazyLoad ? 700 : undefined}
							width="100%"
							margin={1}
							mb={shouldLazyLoad ? 4 : undefined}
						>
							<DataGridPremium
								autoHeight={!shouldLazyLoad}
								rows={rows}
								columns={columns.map((column) => ({ ...column, sortable: false, flex: 1 }))}
								sx={{
									...dataGridStandard,
									borderRadius: '0 15px 15px 15px',
								}}
								getRowHeight={() => 'auto'}
								disableRowSelectionOnClick
								hideFooter
								onCellClick={(cell) => {
									onEditTask(cell.row.id)
								}}
								disableColumnMenu
								rowCount={rowCount}
								pinnedColumns={{ left: [ColumnId.Action] }}
								getRowClassName={getRowClassnames}
								getCellClassName={getCellClassnames}
							/>
						</Box>
					</AccordionDetails>
				}
			</Accordion>
		</Box>
	)
}

export default ProjectSectionTasks
