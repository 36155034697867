import React, { ComponentType } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { ClientTypes, ContactTypes } from '@cango-app/types'

import { selectors as userSelectors } from 'src/store/modules/user'
import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { Box, Button, Text, TextField, Select, Toggle } from 'src/components'
import { actions as roleActions, RoleWithUsage } from 'src/store/modules/roles'

type RoleFormProps = {
	role?: RoleWithUsage
	closeDrawer: () => void
	setShouldPromptDelete: (tf: boolean) => void
	setUpdating: (shouldUpdate: boolean) => void
}

type RoleFormType = Omit<ClientTypes.Role, '_id'>

export const RoleForm: ComponentType<RoleFormProps> = ({
	role,
	closeDrawer,
	setShouldPromptDelete,
	setUpdating,
}) => {
	const dispatch = useDispatch()
	const { handleSubmit, control, watch } = useForm<RoleFormType>({
		defaultValues: {
			label: role?.label,
			internal: role?.internal,
			defaultUser: role?.defaultUser,
			defaultContact: role?.defaultContact,
		},
	})

	const [selectedInternalExternal] = watch(['internal'])

	const users = useSelector(userSelectors.getAllUsersForSelect)
	const contacts = useSelector(contactSelectors.getContactsForSelect)

	const onSubmit = async (roleData: RoleFormType) => {
		setUpdating(true)
		roleData.internal = !!roleData.internal
		if (role?._id) {
			await dispatch(roleActions.updateRole({ _id: role._id, ...roleData }))
		} else {
			await dispatch(roleActions.addRole(roleData))
		}
		setUpdating(false)
		closeDrawer()
	}

	return (
		<Box width={400} p={3} role="presentation">
			<Text sx={{ mb: 3 }} variant="h4">
				{role ? role.label : 'New Role'}
			</Text>
			<form>
				<Controller
					name={'internal'}
					control={control}
					render={({ field: { onChange, value } }) => (
						<Toggle
							options={[
								{ label: 'Internal Role', value: true },
								{ label: 'External Role', value: false },
							]}
							value={value}
							onChange={onChange}
							containerProps={{ mb: 1 }}
						/>
					)}
				/>
				<Controller
					name={'label'}
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextField
							error={!!error}
							fullWidth
							required
							onChange={onChange}
							value={value}
							label={'Role name'}
							containerProps={{ mb: 3, flex: 2 }}
						/>
					)}
				/>
				{selectedInternalExternal ? (
					<Controller
						name={'defaultUser'}
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								label="Who is the default user for this role?"
								onChange={onChange}
								value={value}
								options={users}
							/>
						)}
					/>
				) : (
					<Controller
						name={'defaultContact'}
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								label="Who is the default contact for this role?"
								onChange={onChange}
								value={value}
								options={contacts.filter(({ type }) => type !== ContactTypes.ContactType.Internal)}
							/>
						)}
					/>
				)}
				<br />
				<Button variant="contained" onClick={handleSubmit(onSubmit)}>
					{role ? 'Update' : 'Create'}
				</Button>
				{role && (
					<Button
						onClick={() => setShouldPromptDelete(true)}
						variant="outlined"
						disabled={(role.usage ?? 0) > 0}
						color="error"
						sx={{ float: 'right' }}
					>
						{(role.usage ?? 0) > 0 ? 'Role in use' : 'Delete'}
					</Button>
				)}
			</form>
		</Box>
	)
}
