import { ComponentType, useContext, useMemo, useState } from 'react'
import { V3BlueprintTypes } from '@cango-app/types'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import _isEmpty from 'lodash/isEmpty'

import { Box, Button, RemoveFlaggedTaskModal, Select, TextField } from 'src/components'
import {
	actions as projectActions,
	selectors as projectSelectors,
} from 'src/store/modules/projects-v3'
import { TaskContext } from 'src/providers/task-provider'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { AsyncDispatchType, RootState } from 'src/store/types'
import { ArchiveButtons } from 'src/modules/my-tasks-v3/components/archive-buttons'

import { useListOptions } from '../../../../../hooks/use-list-options'

export const CompleteTaskOps: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const project = useSelector(projectSelectors.getSelectedProject)
	const [isResolveFlaggedTaskModalOpen, setIsResolveFlaggedTaskModalOpen] = useState(false)
	const { task, onCloseDrawer, nextTaskDbChains } = useContext(TaskContext)
	const [instanceName, setInstanceName] = useState('')
	const [selectedOptions, setSelectedOptions] = useState<string[]>([])
	const [isCompleting, setIsCompleting] = useState(false)
	const taskDescendants = useSelector((state: RootState) =>
		projectSelectors.getTaskDescendants(state, task?._id),
	)
	const listOptions = useListOptions(task?.step?.options || [])

	const isTaskCompletable = useMemo(() => {
		return taskDescendants.every(({ lifecycle }) => lifecycle.complete)
	}, [taskDescendants])

	const handleArchiveProject = async () => {
		if (!task) return
		const archiveDate = dayjs().unix()
		await dispatch(
			projectActions.updateProject({
				projectId: task.project_id,
				update: { archived: { state: true, at: archiveDate } },
			}),
		)

		if (onCloseDrawer) {
			onCloseDrawer()
		}
	}

	const handleCompleteTask = async (data?: {
		selectedOptions?: string[]
		instanceName?: string
		resolveFlaggedTask?: boolean
	}) => {
		if (!task) return
		setIsCompleting(true)
		try {
			await dispatch(
				projectActions.completeTask({
					options:
						data?.selectedOptions?.reduce((_acc: { _id: string; label: string }[], _option) => {
							const selectedOption = listOptions.find((_listOption) => _listOption._id === _option)
							if (selectedOption) {
								_acc.push(selectedOption)
							}
							return _acc
						}, []) || [],
					instanceName: data?.instanceName || undefined,
					taskId: task._id,
					hasResolveFlagged: data?.resolveFlaggedTask,
					taskDbChains: !_isEmpty(nextTaskDbChains) ? nextTaskDbChains : undefined,
					projectId: task.project_id,
				}),
			)
			if (task.unblock_task && data?.resolveFlaggedTask) {
				dispatch(
					projectActions.resolveFlaggedTask({
						projectId: task?.project_id,
						taskId: task.unblock_task._id,
					}),
				)
			}
		} catch (error) {
			showSnackbar('Error completing task', { variant: 'error' })
		} finally {
			setIsCompleting(false)
		}
		if (onCloseDrawer) {
			onCloseDrawer()
		}
	}

	const onOpenModal = () => {
		setIsResolveFlaggedTaskModalOpen(true)
	}

	const onCloseModal = () => {
		setIsResolveFlaggedTaskModalOpen(false)
	}

	if (!task) {
		return null
	}

	if (task.isMultiUse) {
		return (
			<>
				<TextField
					value={instanceName}
					onChange={(e) => setInstanceName(e.target.value)}
					fullWidth
					sx={{ mb: 1 }}
				/>
				<Button
					variant="contained"
					onClick={() => {
						handleCompleteTask({ selectedOptions, instanceName })
					}}
					disabled={isCompleting || (task.isMultiUse && instanceName.length < 1)}
					isLoading={isCompleting}
				>
					Use
				</Button>
			</>
		)
	}

	if (!isTaskCompletable) return <>Task has incomplete children</>

	if (task.actions.some((_action) => _action.type === V3BlueprintTypes.ActionEnum.Archive)) {
		return (
			<ArchiveButtons onArchiveProject={handleArchiveProject} onCompleteTask={handleCompleteTask} />
		)
	}

	if (!listOptions.length) {
		return (
			<>
				<RemoveFlaggedTaskModal
					isOpen={isResolveFlaggedTaskModalOpen}
					blockedTaskName={task.unblock_task?.name}
					onClose={onCloseModal}
					onComplete={() => handleCompleteTask({ selectedOptions })}
					onCompleteAndResolve={() =>
						handleCompleteTask({ selectedOptions, resolveFlaggedTask: true })
					}
				/>
				<Button
					variant="contained"
					onClick={() => {
						task.unblock_task ? onOpenModal() : handleCompleteTask({ selectedOptions })
					}}
					disabled={isCompleting || (task.isMultiUse && instanceName.length < 1)}
					isLoading={isCompleting}
				>
					Complete
				</Button>
			</>
		)
	}

	return (
		<>
			{!project?.archived.state && (
				<>
					<RemoveFlaggedTaskModal
						isOpen={isResolveFlaggedTaskModalOpen}
						blockedTaskName={task.unblock_task?.name}
						onClose={onCloseModal}
						onComplete={() => handleCompleteTask({ selectedOptions, instanceName })}
						onCompleteAndResolve={() =>
							handleCompleteTask({ selectedOptions, instanceName, resolveFlaggedTask: true })
						}
					/>
					<Box>
						<Select
							multiline={task.isMenu}
							multiple={task.isMenu}
							options={listOptions}
							value={selectedOptions}
							onChange={(e) =>
								task.isMenu
									? setSelectedOptions(e.target.value as string[])
									: setSelectedOptions([e.target.value] as string[])
							}
							placeholder="Choose an option"
							containerProps={{ mb: 1, maxWidth: 600 }}
						/>
						<Button
							onClick={() => {
								task.unblock_task
									? onOpenModal()
									: handleCompleteTask({ selectedOptions, instanceName })
							}}
							disabled={!selectedOptions.length || isCompleting}
							isLoading={isCompleting}
							sx={{ mb: 1 }}
						>
							Confirm selection
						</Button>
					</Box>
				</>
			)}
		</>
	)
}
