import { V3BlueprintTypes } from '@cango-app/types'
import { useContext, useMemo } from 'react'
import _isArray from 'lodash/isArray'
import { useSelector } from 'react-redux'

import { columnValueGetter, resolveAnyRowCalculations } from '../modules/tables/utils'
import { TableContext } from '../providers/table-provider'
import { selectors as contactSelectors } from '../store/modules/contacts'

export const useListOptions = (options: V3BlueprintTypes.StepOptions) => {
	const { table, mappedColumns } = useContext(TableContext)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)

	return useMemo(() => {
		if (!options) {
			return []
		}

		if (_isArray(options)) {
			return options
		}

		const field = mappedColumns.get(options)

		if (!field) {
			return []
		}

		const valueOptions = new Map(
			field.valueOptions.map((option) => [
				option._id,
				{
					_id: option._id,
					label: option.label,
				},
			]),
		)
		return (
			table?.records.map((record) => {
				const resolvedRow = resolveAnyRowCalculations({
					row: record,
					fields: table?.fields ?? [],
					contacts: mappedContacts,
				})
				const value = columnValueGetter(resolvedRow[options], field, valueOptions)
				return {
					_id: record._id,
					label: String(value),
				}
			}) ?? []
		)
	}, [options, table?.records, mappedColumns])
}
