import { FormControl, FormHelperText, Slide } from '@mui/material'
import { ComponentType, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { CommsSdk, V3ProjectSdk } from '@cango-app/sdk'
import { v4 } from 'uuid'
import { Mention, MentionsInput } from 'react-mentions'

import { CloseIcon } from 'src/assets/icons'
import {
	Box,
	Button,
	GroupedSelect,
	GroupedSelectOption,
	IconButton,
	Select,
	Text,
} from 'src/components'
import { colors } from 'src/theme/colors'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { actions as notesActions, selectors as notesSelectors } from 'src/store/modules/notes'
import { selectors as userSelectors } from 'src/store/modules/user'
import {
	actions as projectActions,
	selectors as projectSelectors,
} from 'src/store/modules/projects-v3'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { AsyncDispatchType } from 'src/store/types'
import { taggedInputStandard, taggedMentionStandard } from 'src/helpers/ui'

type ComposeMessageProps = {
	show: boolean
	onClose: () => void
}

type ComposeMessageForm = {
	projectId: string
	taskId: string
	message: string
}

const getProjectId = (inboxNavState: CommsSdk.InboxNavState | string) => {
	if (
		![CommsSdk.InboxNavState.MyMessages, CommsSdk.InboxNavState.AllMessages].includes(
			inboxNavState as CommsSdk.InboxNavState,
		)
	) {
		return inboxNavState
	}
	return ''
}

const initialFormValues: ComposeMessageForm = {
	projectId: '',
	taskId: '',
	message: '',
}

export const ComposeMessage: ComponentType<ComposeMessageProps> = ({ show, onClose }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const headers = useSelector(authSelectors.getAuthHeaders)
	const [isProjectsLoading, setIsProjectsLoading] = useState(false)
	const [isTasksLoading, setIsTasksLoading] = useState(false)
	const projectsList = useSelector(projectSelectors.getProjectsListForSelect)
	const [tasks, setTasks] = useState<GroupedSelectOption[]>([])
	const [isSubmitting, setIsSubmitting] = useState(false)
	const inboxNavState = useSelector(notesSelectors.getInboxNavState)
	const userId = useSelector(userSelectors.getCurrentUserId)
	const users = useSelector(userSelectors.getAllUsersForTagging)
	const { control, watch, reset, handleSubmit, setValue } = useForm<ComposeMessageForm>({
		defaultValues: initialFormValues,
	})
	const selectedProjectId = watch('projectId')

	const fetchProjects = useCallback(async () => {
		setIsProjectsLoading(true)
		await dispatch(projectActions.getProjectNames())
		setIsProjectsLoading(false)
	}, [])

	const fetchTasks = useCallback(async (projectId: string) => {
		try {
			setIsTasksLoading(true)
			const tasks = await V3ProjectSdk.getTasks({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders: headers,
				projectId,
				params: {
					filters: ['_id', 'name', 'section_id', 'instance', 'section'],
				},
			})

			// const initialGroups: Array<GroupedSelectOption & { sectionId: string }> = tasks
			// 	.filter((task) => task.isSection) // Filter to get only sections
			// 	.map((section) => ({
			// 		sectionId: section._id,
			// 		groupName: section.name,
			// 		options: [] as { _id: string; label: string }[],
			// 	}))
			const initialGroups = tasks.reduce((_acc: GroupedSelectOption[], task) => {
				const existingIndex = _acc.findIndex((group) => group.groupName === task.section)
				if (!existingIndex) {
					_acc.push({
						groupName: task.section,
						options: [{ _id: task._id, label: task.name }],
					})
				} else {
					_acc[existingIndex].options.push({ _id: task._id, label: task.name })
				}
				return _acc
			}, [])

			// Optional Step 3: Remove groups with no tasks (if needed)
			const filteredGroups = initialGroups.filter((group) => group.options.length > 0)

			setTasks(filteredGroups)
		} catch (error) {
			showSnackbar('Unable to fetch tasks', { variant: 'error' })
		} finally {
			setIsTasksLoading(false)
		}
	}, [])

	const handleFormSubmit = async (formData: ComposeMessageForm) => {
		setIsSubmitting(true)
		const response = await dispatch(
			notesActions.sendNote({
				taskId: formData.taskId,
				note: formData.message,
				tempId: v4(),
				userId,
			}),
		)
		setIsSubmitting(false)
		if (response.meta.requestStatus === 'fulfilled') {
			dispatch(notesActions.getInbox(inboxNavState))
			onClose()
		}
	}

	useEffect(() => {
		if (selectedProjectId) {
			fetchTasks(selectedProjectId)
		}
	}, [selectedProjectId])

	useEffect(() => {
		if (show) {
			fetchProjects()
			setValue('projectId', getProjectId(inboxNavState))
		} else {
			reset(initialFormValues)
		}
	}, [show])

	return (
		<Box position="absolute" right={24} bottom={0}>
			<Slide direction="up" in={show} mountOnEnter unmountOnExit>
				<div>
					<Box
						sx={{
							borderTopLeftRadius: 8,
							borderTopRightRadius: 8,
							width: '600px',
							height: '600px',
							bgcolor: colors.neutral['10'],
							border: '1px solid',
							borderColor: colors.neutral['40'],
							p: 2,
							zIndex: 10,
							position: 'relative',
							boxShadow: 2,
						}}
					>
						<Box display="flex" justifyContent="space-between">
							<Text variant="overline">Compose message</Text>
							<Box>
								<IconButton size="small" onClick={onClose}>
									<CloseIcon />
								</IconButton>
							</Box>
						</Box>
						<Controller
							control={control}
							name="projectId"
							render={({ field, fieldState: { error } }) => (
								<Select
									{...field}
									options={projectsList}
									isLoading={isProjectsLoading}
									label="Project"
									error={!!error}
									containerProps={{ mb: 2 }}
								/>
							)}
						/>
						<Controller
							control={control}
							name="taskId"
							rules={{ required: 'Select a task' }}
							render={({ field, fieldState: { error } }) => (
								<GroupedSelect
									{...field}
									label="Task"
									options={tasks}
									isLoading={isTasksLoading}
									disabled={!selectedProjectId}
									containerProps={{ mb: 2 }}
									error={!!error}
									helperText={error?.message}
								/>
							)}
						/>
						<Controller
							control={control}
							name="message"
							rules={{ required: 'A message is required' }}
							render={({ field: { value, onChange }, fieldState: { error } }) => (
								<Box flex={1}>
									<Text fontSize={14} color={colors.neutral['80']} fontWeight={500}>
										Message
									</Text>
									<Box flex={1} bgcolor="#fff" borderRadius="8px" mb={2}>
										<FormControl fullWidth>
											<MentionsInput
												className="cango-mentions-input"
												value={value}
												onChange={onChange}
												style={{ ...taggedInputStandard }}
												autoFocus={false}
												forceSuggestionsAboveCursor
											>
												<Mention
													className="cango-mention"
													trigger="@"
													data={users}
													style={taggedMentionStandard}
												/>
											</MentionsInput>
											{!!error && <FormHelperText error={!!error}>{error.message}</FormHelperText>}
										</FormControl>
									</Box>
								</Box>
							)}
						/>
						<Box display="flex" justifyContent="flex-end">
							<Button onClick={handleSubmit(handleFormSubmit)} isLoading={isSubmitting}>
								Send
							</Button>
						</Box>
					</Box>
				</div>
			</Slide>
		</Box>
	)
}
