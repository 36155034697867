import { ComponentType, useMemo } from 'react'
import { Link } from 'react-router-dom'
import _trim from 'lodash/trim'

import { Box, Text } from 'src/components'

type SoftwareProps = {
	links: { _id: string; link: string }[]
}

export const Software: ComponentType<SoftwareProps> = ({ links }) => {
	const trimmedLinks = useMemo(() => {
		return links.map(({ link }) => _trim(link))
	}, [links])
	return (
		<Box>
			{trimmedLinks.map((_link) => (
				<Link
					key={_link}
					to={_link}
					target="_blank"
					style={{
						textDecoration: 'none',
					}}
				>
					<Text fontWeight={400} color="#2799F4" mb={2}>
						{_link}
					</Text>
				</Link>
			))}
		</Box>
	)
}
