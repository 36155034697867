import React, { ComponentType, useContext, useMemo, useState } from 'react'
import { EdgeProps, BaseEdge, EdgeLabelRenderer, getBezierPath } from 'reactflow'

import { ChainIcon, CheckSquare, DatabaseIcon, SingleOptionIcon } from '../../../../assets/icons'
import { Box } from '../../../../components'
import { colors } from '../../../../theme/colors'
import { ChainContext } from '../../chain-provider'
import { TableContext } from '../../../../providers/table-provider'
import { useListOptions } from '../../../../hooks/use-list-options'
import { EdgeData } from '../../types'

import { OptionListItem } from './dependency-option-list-item'
import { DependencyModal } from './dependency-modal'

export const ChainDependencyEdgeContainer: ComponentType<EdgeProps<EdgeData>> = ({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	data,
	source,
	target,
}) => {
	const { onUpdateConnection } = useContext(ChainContext)
	const { mappedColumns } = useContext(TableContext)
	const [showSettingsModal, setShowSettingsModal] = useState(false)
	const [edgePath, labelX, labelY] = getBezierPath({
		sourceX,
		sourceY,
		targetX,
		targetY,
	})
	const listOptions = useListOptions(data?.options ?? [])

	const handleDeleteConnection = () => {
		setShowSettingsModal(false)
		onUpdateConnection({
			connection: { source, target },
			method: 'remove',
			createForEveryOption: false,
			thread: null,
			databaseLogic: null,
			option_condition: undefined,
		})
	}

	// const mappedSelections = useMemo(() => {
	// 	return new Map(
	// 		targetChild?.option_condition?.values.map((_option) => [
	// 			_option,
	// 			listOptions.find((_listOption) => _listOption._id === _option)?.label ?? '',
	// 		]),
	// 	)
	// }, [targetChild?.option_condition?.values])

	const connectionVariant = useMemo(() => {
		if (!data?.child?.option_condition) {
			return 'complete'
		}

		if (data.child.createForEveryOption) {
			return 'createForEveryOption'
		}

		return 'selectedOptions'
	}, [data])

	const backgroundColor = useMemo(() => {
		if (!connectionVariant) {
			return '#fff'
		}

		if (connectionVariant === 'complete' && !data?.child?.option_condition) {
			return colors.feldgrau['20']
		}

		return colors.sunglow['20']
	}, [connectionVariant, data?.nextStep])

	const fontColor = useMemo(() => {
		if (!connectionVariant) {
			return colors.neutral['80']
		}

		if (connectionVariant === 'complete' && !data?.child?.option_condition) {
			return colors.feldgrau['80']
		}

		return colors.feldgrau['60']
	}, [connectionVariant, data?.nextStep])

	const selectedOptions = useMemo(() => {
		if (data?.isMenu) {
			return 'create one chain for selected options'
		}
		const optionConditionValues = data?.child?.option_condition?.values ?? []
		if (optionConditionValues.length) {
			return (
				listOptions.find((_listOption) => _listOption._id === optionConditionValues[0])?.label ?? ''
			)
		}
		return ''
	}, [data, listOptions])

	return (
		<>
			{showSettingsModal && data && (
				<DependencyModal
					options={data.options}
					isMenu={data.isMenu}
					onClose={() => setShowSettingsModal(false)}
					source={source}
					target={target}
					onDeleteConnection={handleDeleteConnection}
					child={data.child}
				/>
			)}
			<BaseEdge id={id} path={edgePath} />
			<EdgeLabelRenderer>
				<Box
					sx={{
						position: 'absolute',
						transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
						pointerEvents: 'all',
						bgcolor: backgroundColor,
						maxWidth: 100,
						p: 1,
						borderRadius: 1,
						color: fontColor,
					}}
					onClick={() => setShowSettingsModal(true)}
				>
					{connectionVariant === 'complete' && (
						<OptionListItem
							icon={<CheckSquare stroke={colors.feldgrau['80']} width={12} />}
							label="on complete"
							// secondaryLabel={
							// 	data.nextStep.thread?.option_conditions.length
							// 		? `${data.nextStep.thread?.option_conditions.length} selections`
							// 		: undefined
							// }
						/>
					)}
					{connectionVariant === 'createForEveryOption' && (
						<OptionListItem
							icon={<ChainIcon fill={colors.feldgrau['40']} />}
							label="create a chain for every option"
						/>
					)}
					{connectionVariant === 'selectedOptions' && (
						<OptionListItem
							icon={
								<SingleOptionIcon
									stroke={colors.feldgrau['40']}
									style={{ transform: 'rotate(180deg)' }}
								/>
							}
							label={selectedOptions}
						/>
					)}
					{!!data?.child?.database_chain_logic?.column && (
						<Box mt={1}>
							<OptionListItem
								icon={<DatabaseIcon stroke={colors.feldgrau['40']} width={12} />}
								label="Database logic"
								secondaryLabel={
									mappedColumns.get(data.child?.database_chain_logic?.column ?? '')?.name ?? ''
								}
							/>
						</Box>
					)}
				</Box>
			</EdgeLabelRenderer>
		</>
	)
}
