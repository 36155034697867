import { ComponentType, MouseEvent, useContext, useMemo, useState } from 'react'
import { Handle, Position, NodeProps } from 'reactflow'
import Alert from '@mui/material/Alert'
import { V3BlueprintTypes } from '@cango-app/types'
import _uniqBy from 'lodash/uniqBy'

import { Box, Checkbox, Chip, IconButton, Text } from 'src/components'
import { PlusIcon } from 'src/assets/icons'
import { ThreadList } from 'src/components/section-tasks-v3/chains-list'
import { colors } from 'src/theme/colors'

import { ChainContext } from '../chain-provider'
import { NodeData, PropogatedNode } from '../types'

// Threads
// 1. Have all parents of a task
// 2. Check connections to check route is correct
// 3. Loop upwards to find all threads

const getThreads = ({
	nodeMap,
	nodeId,
	visitedNodes = new Set(),
}: {
	nodeMap: Map<string, PropogatedNode>
	nodeId: string
	visitedNodes?: Set<string>
}): V3BlueprintTypes.Thread[] => {
	const node = nodeMap.get(nodeId)
	if (!node || visitedNodes.has(nodeId)) return []
	visitedNodes.add(nodeId)
	const threads: V3BlueprintTypes.Thread[] = []
	const directParents = node.data.parents.reduce((_parents: PropogatedNode[], _parentId) => {
		const parent = nodeMap.get(_parentId)
		if (!parent) return _parents
		if (!parent.data.descendants.some((_desc) => _desc.step === nodeId)) {
			return _parents
		}
		_parents.push(parent)
		return _parents
	}, [])

	for (const _directParent of directParents) {
		_directParent.data.descendants.forEach((_desc) => {
			if (_desc.step !== nodeId) return
			if (_desc.database_chain_logic) {
				threads.push({
					color: '#c4def6',
					prefix: _desc.database_chain_logic.column,
					_id: _desc._id,
				})
				return
			}
			if (_desc.thread?._id) {
				threads.push(_desc.thread)
				return
			}
		})
		if (_directParent.data.isLastStepInChain) {
			continue
		}
		const parentOfDirectParentThreads = getThreads({
			nodeMap,
			nodeId: _directParent.data._id,
			visitedNodes,
		})
		threads.push(...parentOfDirectParentThreads)
	}

	return _uniqBy(threads, '_id')
}

const StandardNode: ComponentType<NodeProps<NodeData>> = ({ data, isConnectable }) => {
	const { onAddStep, updateLastStepInChain, nodeDescendantsMap, nodeMap, stepCycles } =
		useContext(ChainContext)
	const [isCreatingStep, setIsCreatingStep] = useState(false)
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

	const handleNewStepClick = async (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation()
		setIsCreatingStep(true)
		await onAddStep(data._id)
		setIsCreatingStep(false)
	}

	const hasLoops = useMemo(() => {
		return [...stepCycles.values()].some((_cycle) => _cycle.source === data._id)
	}, [stepCycles, data._id])

	const threads = useMemo(() => {
		return getThreads({ nodeMap, nodeId: data._id })
	}, [nodeMap, data._id])

	const handleLastStepInChange = async () => {
		if (!threads.length) return
		setIsLoadingUpdate(true)
		const descendants = nodeDescendantsMap.get(data._id)
		if (!descendants) return
		const currentNode = data.nodes.find((_node) => _node.data._id === data._id)
		if (!currentNode) return
		let oldSteps: string[] = []
		if (currentNode.data.isLastStepInChain) {
			oldSteps = [data._id]
		} else {
			data.nodes.forEach((_node) => {
				const isDescendant = descendants.some((_desc) => _desc.id === _node.data._id)
				if (!isDescendant) return
				if (_node.data.isLastStepInChain) {
					oldSteps.push(_node.data._id)
				}
			})
		}
		const newStepId = !currentNode.data.isLastStepInChain ? data._id : ''
		await updateLastStepInChain(oldSteps, newStepId)
		setIsLoadingUpdate(false)
	}

	return (
		<Box
			bgcolor={!data.isSection ? '#fff' : colors.lightCoral['20']}
			width={200}
			pt={2}
			pb={1}
			px={1}
			borderRadius={1}
			position="relative"
		>
			<Handle type="target" position={Position.Top} isConnectable={isConnectable} />
			{!data.isSection && (
				<Text fontSize={12} mb={1}>
					Section:
					<b> {data.sections.join(', ')}</b>
				</Text>
			)}
			<Text fontSize={14} textAlign="center" mb={1}>
				{data.isSection && <b>Section: </b>}
				{data.name}
			</Text>
			{!!data.isMultiUse && (
				<Box mb={1}>
					<Chip label="multi-use" size="small" color="info" />
				</Box>
			)}
			{!!threads.length && <ThreadList threads={[...threads.values()]} />}
			{!!threads.length && !data.isSection && (
				<Box>
					<Checkbox
						label="Last step in chain"
						size="small"
						checked={data.isLastStepInChain}
						fontSize={10}
						disabled={isLoadingUpdate}
						onClick={(event) => {
							event.stopPropagation()
							handleLastStepInChange()
						}}
					/>
				</Box>
			)}
			{!data.isSection && !data.roles.length && <Alert severity="warning">No roles assigned</Alert>}
			<Box display="flex" justifyContent="space-between">
				<Box>{hasLoops && <Chip label="Loop" size="small" color="sunglow" />}</Box>
				<IconButton size="small" onClick={handleNewStepClick} isLoading={isCreatingStep}>
					<PlusIcon width={12} />
				</IconButton>
			</Box>
			<Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
		</Box>
	)
}

export default StandardNode
