import { ComponentType, MouseEvent, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Badge from '@mui/material/Badge'

import { Box, Chip } from 'src/components'
import { selectors as notesSelectors } from 'src/store/modules/notes'
import { InboxNavIcon } from 'src/assets/icons'
import { ListedTask } from 'src/store/modules/projects-v3'

import { TaskTitle } from '../../components/task-title'

import { TaskListItemCta } from './ctas/task-list-item-cta'
import { TaskItemWrapper } from './task-item-wrapper'

type TaskListItemProps = {
	task: ListedTask
	isFirstTask: boolean
	isLastTask: boolean
	onClick: () => void
	hideCta?: boolean
	hasIndent?: boolean
}

export const TaskListItem: ComponentType<TaskListItemProps> = (props) => {
	const { task, hideCta } = props
	const unreadTasks = useSelector(notesSelectors.getTasksWithUnreadNotes)

	const isUnreadTask = useMemo(() => {
		return unreadTasks.find(({ taskId }) => taskId === task._id)
	}, [unreadTasks, task._id])

	const badgeContent = useMemo(() => {
		if (isUnreadTask) return <InboxNavIcon fill="#fff" width={14} />
		return undefined
	}, [isUnreadTask, task.isFlagged])

	return (
		<TaskItemWrapper isMultiUse={task.isMultiUse} {...props}>
			<Box display="flex" alignItems="center">
				<TaskTitle task={task} containerProps={{ flex: 1, mr: 1 }} />
				{!hideCta && (
					<Badge color="error" badgeContent={badgeContent}>
						{!!task.isFlagged && (
							<Chip label="blocked" color="error" variant="status" size="small" sx={{ mr: 3 }} />
						)}
						<TaskListItemCta task={task} />
					</Badge>
				)}
			</Box>
		</TaskItemWrapper>
	)
}
