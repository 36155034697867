import React, { ComponentType, useState } from 'react'
import { useSelector } from 'react-redux'

import { Drawer } from 'src/components'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import type { RootState } from 'src/store/types'

import { DeleteRole } from './delete-role'
import { RoleForm } from './role-form'

type RoleFormProps = {
	open: boolean
	roleId?: string
	setShowRoleDrawer: (open: boolean) => void
	setSelectedRole: (roleId: string | undefined) => void
}

const RoleDrawer: ComponentType<RoleFormProps> = ({
	open,
	roleId,
	setShowRoleDrawer,
	setSelectedRole,
}) => {
	const [shouldPromptDelete, setShouldPromptDelete] = useState(false)
	const [updating, setUpdating] = useState(false)

	const role = useSelector((state: RootState) => roleSelectors.getRoleById(state, roleId))

	const handleCloseDrawer = () => {
		setSelectedRole(undefined)
		setShouldPromptDelete(false)
		setShowRoleDrawer(false)
	}

	return (
		<Drawer open={open} onClose={handleCloseDrawer} isLoading={updating}>
			{shouldPromptDelete && role ? (
				<DeleteRole role={role} closeDrawer={handleCloseDrawer} />
			) : (
				<RoleForm
					role={role}
					closeDrawer={handleCloseDrawer}
					setShouldPromptDelete={setShouldPromptDelete}
					setUpdating={setUpdating}
				/>
			)}
		</Drawer>
	)
}

export default RoleDrawer
