import React, { ComponentType } from 'react'
import { FormControlLabel, Radio } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { V3BlueprintTypes } from '@cango-app/types'
import { v4 } from 'uuid'

import { Box, Select, Text, TextField } from '../../../../components'
import { useListOptions } from '../../../../hooks/use-list-options'
import { ThreadList } from '../../../../components/section-tasks-v3/chains-list'

import { DependencyForm, DependencyType } from './types'

type ChainForEachOptionProps = {
	options: V3BlueprintTypes.StepOptions
	isMenu: boolean
	dependencyType: DependencyType
}

export const ChainForEachOption: ComponentType<ChainForEachOptionProps> = ({
	options,
	isMenu,
	dependencyType,
}) => {
	const { control, watch, setValue } = useFormContext<DependencyForm>()
	const listOptions = useListOptions(options)
	const hasDatabaseLogicSelected = !!watch('databaseLogic')
	const isAnySelected =
		watch('option_condition.operator') === V3BlueprintTypes.ChildConditionOperator.Any

	return (
		<Box>
			<FormControlLabel
				value={DependencyType.A_UNIQUE_CHAIN_FOR_EACH_OPTION_SELECTED}
				control={<Radio />}
				disabled={listOptions.length === 0}
				label={<Text fontSize={14}>{'when my selection'}</Text>}
			/>
			{dependencyType === DependencyType.A_UNIQUE_CHAIN_FOR_EACH_OPTION_SELECTED && (
				<Box maxWidth={400} ml={3}>
					<Controller
						control={control}
						name="option_condition.operator"
						render={({ field: { value, onChange } }) => {
							const handleChangeValue = (newValue: V3BlueprintTypes.ChildConditionOperator) => {
								if (newValue === V3BlueprintTypes.ChildConditionOperator.Any) {
									setValue('option_condition.values', [])
								}
								onChange(newValue)
							}

							return (
								<Select
									value={value}
									onChange={(e) =>
										handleChangeValue(e.target.value as V3BlueprintTypes.ChildConditionOperator)
									}
									options={[
										{
											_id: V3BlueprintTypes.ChildConditionOperator.Is,
											label: isMenu ? 'includes' : 'is',
										},
										{
											_id: V3BlueprintTypes.ChildConditionOperator.IsNot,
											label: isMenu ? 'does not include' : 'is not',
										},
										{
											_id: V3BlueprintTypes.ChildConditionOperator.Any,
											label: 'is anything',
										},
									]}
								/>
							)
						}}
					/>
					{!isAnySelected && (
						<Controller
							control={control}
							name="option_condition.values"
							render={({
								field: { value: selectedOptions, onChange: onSelectedOptionsChange },
							}) => (
								<Select
									options={listOptions}
									value={selectedOptions}
									multiple={isMenu}
									disabled={isAnySelected}
									onChange={(event) => {
										if (!isMenu) {
											onSelectedOptionsChange([event.target.value as string])
											return
										}
										onSelectedOptionsChange(event.target.value as string[])
									}}
									containerProps={{ maxWidth: 400 }}
									helperText={
										!isMenu
											? 'The parent task only allows one option to be selected'
											: 'Multiple options can be selected'
									}
								/>
							)}
						/>
					)}
					{!hasDatabaseLogicSelected && (
						<Controller
							control={control}
							name="chainPrefix"
							render={({ field: { value: chainPrefix, onChange: onChainPrefixChange } }) => {
								return (
									<TextField
										label="Give this chain a name"
										value={chainPrefix}
										disabled={hasDatabaseLogicSelected}
										onChange={(e) => onChainPrefixChange(e.target.value)}
										containerProps={{ sx: { my: 2 } }}
										helperText={
											<Box>
												<Text
													fontSize={12}
												>{`This will appear on all chains for clarity of which task belongs to which chain.`}</Text>
												<Box display="flex" alignItems="center">
													<Text fontSize={12} mr={1}>
														{'e.g.'}
													</Text>
													<ThreadList
														threads={[
															{
																_id: v4(),
																prefix: chainPrefix || '[example]',
																color: '#c4def6',
															},
														]}
													/>
												</Box>
											</Box>
										}
									/>
								)
							}}
						/>
					)}
				</Box>
			)}
		</Box>
	)
}
