import { ComponentType, useContext, useEffect, useMemo, useState } from 'react'
import { V3ClientTypes } from '@cango-app/types'
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'

import { TableContext } from '../../../../providers/table-provider'
import { Box, Button, Modal } from '../../../../components'
import { TaskContext } from '../../../../providers/task-provider'
import { usePrevious } from '../../../../hooks/usePrevious'
import { DisplayView } from '../../../../components/display-view'
import { getFilters } from '../../../tables/utils'

type TableContentProps = {
	action: V3ClientTypes.Project.TaskAction
}

const TableContent: ComponentType<TableContentProps> = ({ action }) => {
	const { task } = useContext(TaskContext)
	const { table, apiRef } = useContext(TableContext)

	const { view, initialState } = useMemo(() => {
		const view = table?.views.find((_view) => _view._id === action.view)
		const initialState: GridInitialStatePremium = {
			filter: {
				filterModel: {
					items: getFilters(table, task?.chain?.database_chain_logic?.filters?.items ?? []),
				},
			},
		}
		return {
			view,
			initialState,
		}
	}, [table?.views, action.view])

	if (!view) {
		return null
	}

	return (
		<Box height={500}>
			<DisplayView
				{...view}
				initialState={initialState}
				datagridProps={{ apiRef, disableColumnFilter: false }}
			/>
		</Box>
	)
}

export const SetResourcesModal: ComponentType<TableContentProps> = (props) => {
	const { task } = useContext(TaskContext)
	const [isModelOpen, setIsModelOpen] = useState(false)
	const previousTask = usePrevious(task?._id)
	const { isLoadingTable, isUpdatingTable } = useContext(TableContext)

	const handleCloseModal = () => {
		if (isLoadingTable || isUpdatingTable) {
			return
		}
		setIsModelOpen(false)
	}

	useEffect(() => {
		if (!previousTask && task?._id) {
			setIsModelOpen(true)
		}
	}, [task?._id, previousTask])

	return (
		<>
			<Modal
				open={isModelOpen}
				onClose={handleCloseModal}
				containerStyle={{ maxWidth: '90vw', minWidth: 700, minHeight: 300, p: 4 }}
			>
				<TableContent {...props} />
			</Modal>
			<Button variant="outlined" onClick={() => setIsModelOpen(true)}>
				Update database fields
			</Button>
		</>
	)
}

export const SetResources = TableContent
