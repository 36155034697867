import React, { ComponentType, useContext, useMemo, useState } from 'react'
import { V3BlueprintTypes } from '@cango-app/types'
import { FormProvider, useForm } from 'react-hook-form'
import { v4 } from 'uuid'
import Alert from '@mui/material/Alert'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import { ChainContext } from '../../chain-provider'
import { Box, Button, Divider, IconButton, Modal, Text } from '../../../../components'
import { CloseIcon } from '../../../../assets/icons'
import { EdgeData } from '../../types'

import { DependencyForm, DependencyType } from './types'
import { DependencyFromDatabase } from './dependency-from-database'
import { ChainForEachOption } from './chain-for-each-option'
import { ChainForEveryOptionSelected } from './chain-for-every-option-selected'

type DependencyModalProps = EdgeData & {
	source: string
	target: string
	onDeleteConnection: () => void
	options: V3BlueprintTypes.StepOptions
	onClose: () => void
	isMenu: boolean
}

const getSelectedDependencyOption = ({
	createForEveryOption,
	option_condition,
}: {
	createForEveryOption?: boolean
	option_condition: V3BlueprintTypes.StepChild['option_condition']
}) => {
	if (!option_condition) {
		return DependencyType.WHEN_THIS_TASK_COMPLETES
	}

	if (createForEveryOption) {
		return DependencyType.CREATE_THE_SAME_CHAIN_FOR_EVERY_OPTION_SELECTED
	}

	return DependencyType.A_UNIQUE_CHAIN_FOR_EACH_OPTION_SELECTED
}

export const DependencyModal: ComponentType<DependencyModalProps> = ({
	options,
	isMenu,
	onClose,
	source,
	target,
	onDeleteConnection,
	child,
}) => {
	const [isUpdatingConnection, setIsUpdatingConnection] = useState(false)
	const { onUpdateConnection } = useContext(ChainContext)
	const formProps = useForm<DependencyForm>({
		defaultValues: {
			createForEveryOption: child.createForEveryOption ?? false,
			chainPrefix: child.thread?.prefix ?? '',
			databaseLogic: child.database_chain_logic,
			option_condition: child.option_condition,
		},
	})
	const { setValue, handleSubmit, watch } = formProps
	const [optionCondition, formCreateForEveryOption, databaseLogic] = watch([
		'option_condition',
		'createForEveryOption',
		'databaseLogic',
	])
	const isDuplicateChainDisabled = formCreateForEveryOption && !!databaseLogic

	const dependencyType = useMemo((): DependencyType => {
		return getSelectedDependencyOption({
			createForEveryOption: formCreateForEveryOption,
			option_condition: optionCondition,
		})
	}, [optionCondition, formCreateForEveryOption])

	const handleSaveDependencyType = async (data: DependencyForm) => {
		setIsUpdatingConnection(true)

		let updatedThread: V3BlueprintTypes.Thread | null = null

		if (data.createForEveryOption || data.chainPrefix) {
			updatedThread = {
				_id: child.thread?._id || v4(),
				prefix: data.chainPrefix ?? child.thread?.prefix ?? '',
			}
		} else if (!data.chainPrefix && !data.createForEveryOption) {
			updatedThread = null
		}

		await onUpdateConnection({
			connection: { source, target },
			method: 'update',
			option_condition: data.option_condition,
			createForEveryOption: data.createForEveryOption,
			thread: updatedThread,
			databaseLogic: data.databaseLogic,
		})

		setIsUpdatingConnection(false)
		onClose()
	}

	const handleDependencyTypeChange = (previousType: DependencyType, newType: DependencyType) => {
		if (newType === DependencyType.WHEN_THIS_TASK_COMPLETES) {
			setValue('option_condition', undefined, { shouldDirty: true })
			setValue('createForEveryOption', false)
			setValue('chainPrefix', '')
			return
		}

		if (previousType === DependencyType.WHEN_THIS_TASK_COMPLETES) {
			setValue('option_condition', {
				values: [],
				operator: V3BlueprintTypes.ChildConditionOperator.Is,
			})
		}

		if (newType === DependencyType.CREATE_THE_SAME_CHAIN_FOR_EVERY_OPTION_SELECTED) {
			setValue('createForEveryOption', true)
		} else {
			setValue('createForEveryOption', false)
		}
	}

	return (
		<FormProvider {...formProps}>
			<Modal open>
				<Box maxWidth={500}>
					<Box position="absolute" right={0}>
						<IconButton onClick={onClose}>
							<CloseIcon width={12} />
						</IconButton>
					</Box>
					<Text variant="h6" mb={2}>
						Change dependency type
					</Text>
					{isDuplicateChainDisabled && (
						<Alert severity="error" sx={{ mb: 2 }}>
							You cannot have both database logic and duplicate a chain for each option selected, as
							it is conflicting logic. If you need to use database logic, please create a normal
							condition without duplicating the chain.
						</Alert>
					)}
					<Box sx={{ width: '100%' }}>
						<FormControl sx={{ maxWidth: 600 }}>
							<FormLabel id="radio-buttons-dependencies">I want the next task to start:</FormLabel>
							<RadioGroup
								aria-labelledby="radio-buttons-dependencies"
								value={dependencyType}
								onChange={(e, value) =>
									handleDependencyTypeChange(dependencyType, value as DependencyType)
								}
							>
								<FormControlLabel
									value={DependencyType.WHEN_THIS_TASK_COMPLETES}
									control={<Radio />}
									label={<Text fontSize={14}>{'when my task completes'}</Text>}
								/>
								<ChainForEachOption
									options={options}
									isMenu={isMenu}
									dependencyType={dependencyType}
								/>
								<ChainForEveryOptionSelected
									options={options}
									isMenu={isMenu}
									dependencyType={dependencyType}
									disabled={!!databaseLogic}
								/>
							</RadioGroup>
							<Divider sx={{ py: 1 }} />
							<DependencyFromDatabase />
						</FormControl>
					</Box>
					<Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
						<Button
							onClick={handleSubmit(handleSaveDependencyType)}
							isLoading={isUpdatingConnection}
							disabled={isUpdatingConnection}
						>
							Save
						</Button>
						<Button variant="outlined" color="error" onClick={onDeleteConnection}>
							Delete connection
						</Button>
					</Box>
				</Box>
			</Modal>
		</FormProvider>
	)
}
