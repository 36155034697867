import { ComponentType } from 'react'

import { ChevronRight } from 'src/assets/icons'
import { ListedTask } from 'src/store/modules/projects-v3'

import { MultiUseCta } from './multi-use-cta'

type TaskListItemCtaProps = {
	task: ListedTask
}

export const TaskListItemCta: ComponentType<TaskListItemCtaProps> = ({ task }) => {
	if (task.isMultiUse) {
		return <MultiUseCta task={task} />
	}

	return <ChevronRight style={{ marginRight: 8 }} />
}
