import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import * as Sentry from '@sentry/react'
import { LicenseInfo } from '@mui/x-license'
import { GoogleOAuthProvider } from '@react-oauth/google'
import Hotjar from '@hotjar/browser'
import { SnackbarProvider } from 'notistack'
import { createRoot } from 'react-dom/client'

import packageInfo from '../package.json'

import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { store, persistor } from './store'
import { initFirebaseApp } from './helpers/firebase'

const environment = import.meta.env.VITE_ENVIRONMENT as string

Sentry.init({
	dsn: 'https://66ad1e1c224440e2ba597f1e0f2d28ac@o4503919681077248.ingest.sentry.io/4503919683764224',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			maskAllInputs: false,
		}),
	],
	environment: environment,
	tracesSampleRate: environment === 'development' ? 1.0 : 0.1,
	replaysOnErrorSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	release: packageInfo.version,
})

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY as string)

if (environment === 'production') {
	const siteId = 3605341
	const hotjarVersion = 6
	Hotjar.init(siteId, hotjarVersion)
}

initFirebaseApp()
const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
	// <React.StrictMode>
	<GoogleOAuthProvider clientId={import.meta.env.VITE_OAUTH_CLIENT_ID as string}>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<SnackbarProvider
					maxSnack={3}
					preventDuplicate
					autoHideDuration={3000}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<App />
				</SnackbarProvider>
			</PersistGate>
		</Provider>
	</GoogleOAuthProvider>,
	// </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
