import { ComponentType, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import LinearProgress from '@mui/material/LinearProgress'

import { Box, Button, Text } from 'src/components'
import {
	selectors as projectSelectors,
	actions as projectActions,
	TaskListType,
	ProjectsLoadingState,
} from 'src/store/modules/projects-v3'
import { UniversalDrawerType, actions as configActions } from 'src/store/modules/config'
import { useMyTasks } from 'src/hooks/useMyTasks'
import { RootState } from 'src/store/types'

import { SingleTaskContainer } from './single-task-container'
import { TaskList } from './task-list'
import { AllMyTasksDone } from './all-my-task-done'

export const DesktopSectionContainer: ComponentType = () => {
	const dispatch = useDispatch()
	const { selectedSectionId, selectedTaskId, goToSection } = useMyTasks()
	const section = useSelector((state: RootState) =>
		projectSelectors.getProjectSection(state, selectedSectionId),
	)
	const selectedTask = useSelector((state: RootState) =>
		projectSelectors.getSelectedTask(state, selectedTaskId),
	)
	const projectState = useSelector(projectSelectors.getProjectLoadingState)
	//const taskListType = useSelector(projectSelectors.getTaskListType)
	const totalFilesRequiringUpload = useSelector(
		projectSelectors.getTotalNumberOfFilesRequiringUpload,
	)
	const numberOfFilesPendingUpload = useSelector(projectSelectors.getNumberOfFilesPendingUpload)
	const taskFilesUploadedSize = useSelector(projectSelectors.getPercentageOfTasksUploaded)
	const [isLoading, setIsLoading] = useState(false)

	/*const handleTaskTypeChange = (type: TaskListType) => {
		dispatch(projectActions.setTaskListType(type))
	}

	const handleNewTaskClick = () => {
		dispatch(configActions.setUniversalDrawerType(UniversalDrawerType.Task))
	}

	const handleTabClick = () => {
		if (taskListType === TaskListType.Active) {
			return
		}
		goToSection(section?._id)
	}*/

	const handleNewProjectClick = () => {
		dispatch(configActions.setUniversalDrawerType(UniversalDrawerType.Project))
	}
	const isProjectLoading = [ProjectsLoadingState.Loading, ProjectsLoadingState.Idle].includes(
		projectState,
	)

	return (
		<Box flex={3} ml={{ laptop: 3 }} height="100%" sx={{ overflowY: 'auto' }}>
			<Box display="flex" justifyContent="flex-end" mt={2} mb={4}>
				<Box>
					<Button onClick={handleNewProjectClick}>New project</Button>
				</Box>
			</Box>
			{isProjectLoading ? (
				<Skeleton variant="rectangular" width="100%" height={400} sx={{ borderRadius: '30px' }} />
			) : null}
			{!section && !isProjectLoading ? (
				<AllMyTasksDone bgcolor="white" borderRadius="30px" px={3} py={2} height={400} />
			) : null}
			{section && !isProjectLoading ? (
				<Box bgcolor="white" borderRadius="30px" px={3} py={2} minHeight={{ laptop: 200 }}>
					{/* {!selectedTask && (
						<Box display="flex" alignItems="center">
							<Tabs
								value={taskListType}
								sx={{ flex: 1 }}
								onChange={(e, value) => handleTaskTypeChange(value)}
							>
								<Tab
									value={TaskListType.Active}
									label={`Active tasks (${activeTasks.length})`}
									onClick={handleTabClick}
								/>
								<Tab
									value={TaskListType.ProjectTasks}
									label={`Project tasks (${masterTasks.length})`}
									disabled={!masterTasks.length}
								/>
							</Tabs>
							<Box display="flex">
								<Button sx={{ mr: 1 }} onClick={handleNewTaskClick} variant="outlined">
									New task
								</Button>
							</Box>
						</Box>
					)} */}
					<Box>
						<Box height="4px">
							{!!isLoading && <LinearProgress variant="indeterminate" />}
							{taskFilesUploadedSize !== 100 && (
								<>
									<LinearProgress variant="determinate" value={taskFilesUploadedSize} />
									<Text fontSize={12} textAlign="center">
										{'Uploading '}
										{totalFilesRequiringUpload - numberOfFilesPendingUpload + 1}
										{' of '}
										{totalFilesRequiringUpload}
									</Text>
								</>
							)}
						</Box>
						{selectedTask ? <SingleTaskContainer onTaskLoading={setIsLoading} /> : <TaskList />}
					</Box>
				</Box>
			) : null}
		</Box>
	)
}
