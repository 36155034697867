import React, { ComponentType, useContext, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { V3BlueprintTypes } from '@cango-app/types'
import { v4 } from 'uuid'
import _uniq from 'lodash/uniq'
import { Alert } from '@mui/material'
import _isArray from 'lodash/isArray'
import _isString from 'lodash/isString'

import { Box, Button, IconButton, Select, TextField, Toggle } from 'src/components'
import { TrashIcon } from 'src/assets/icons'

import { TableContext } from '../../../../../providers/table-provider'

import { StepFormType } from './step-form-container'

type ChildItemProps = {
	index: number
	onRemove: () => void
	item: { _id: string; label: string }
}

enum ListOriginType {
	Custom = 'Custom',
	DatabaseColumn = 'DatabaseColumn',
}

const ChildItem: ComponentType<ChildItemProps> = ({ item, onRemove, index }) => {
	const { control, watch } = useFormContext<StepFormType>()
	const allOptions = watch('options') as { _id: string; label: string }[]

	return (
		<Controller
			control={control}
			name={`options.${index}.label`}
			rules={{
				required: 'Option cannot be empty',
				validate: (value) => {
					if (allOptions.some((_opt) => _opt._id !== item._id && _opt.label === item.label)) {
						return 'Option must be unique'
					}
					return true
				},
			}}
			render={({ field: { value, onChange }, fieldState: { error } }) => {
				return (
					<Box display="flex" alignItems="center">
						<TextField
							label={`Option ${index + 1}`}
							onChange={(e) => onChange(e.target.value)}
							value={value}
							fullWidth
							containerProps={{ mr: 1.5, my: 1.5, flex: 1 }}
							error={!!error}
							helperText={error?.message}
						/>
						<IconButton onClick={onRemove}>
							<TrashIcon />
						</IconButton>
					</Box>
				)
			}}
		/>
	)
}

export const UndecoratedChildFields: ComponentType = () => {
	const { control, watch, setValue } = useFormContext<StepFormType>()
	const [isMultiUse, chainActions, options] = watch(['isMultiUse', 'chain_actions', 'options'])
	const { table, mappedColumns } = useContext(TableContext)

	const hasArchiveTypeAction = useMemo(() => {
		return chainActions.some((action) =>
			action.actions.some((a) => a.type === V3BlueprintTypes.ActionEnum.Archive),
		)
	}, [chainActions])

	const columnHasDuplicatedValues = useMemo(() => {
		if (!options || _isArray(options)) {
			return false
		}
		if (!table?.records.length || !options) return false
		const columnValues = table.records.reduce((_colValues: string[], _record) => {
			if (!_record.data[options]) {
				return _colValues
			}
			return [..._colValues, String(_record.data[options])]
		}, [])
		return _uniq(columnValues).length !== columnValues.length
	}, [options, table?.records])

	const handleOptionTypeChange = (oldValue: ListOriginType, newValue: ListOriginType) => {
		if (oldValue === newValue) {
			return
		}
		if (oldValue === ListOriginType.Custom) {
			setValue('options', table?.principal_field ?? '', { shouldDirty: true })
			return
		}
		setValue('options', [], { shouldDirty: true })
	}

	const handleRemoveItem = (_id: string) => {
		if (!_isArray(options)) {
			return
		}
		setValue(
			'options',
			options.filter((option) => option._id !== _id),
		)
	}

	const handleNewItem = () => {
		if (!_isArray(options)) {
			return
		}
		setValue(
			'options',
			[
				...options,
				{
					_id: v4(),
					label: '',
				},
			],
			{ shouldDirty: true },
		)
	}

	if (isMultiUse || hasArchiveTypeAction || !options) {
		return null
	}

	return (
		<Box mt={2}>
			<Controller
				control={control}
				name="options"
				render={({ field: { value } }) => {
					const currentOptionType = _isString(value)
						? ListOriginType.DatabaseColumn
						: ListOriginType.Custom
					return (
						<Toggle
							value={currentOptionType}
							onChange={(newValue) =>
								handleOptionTypeChange(currentOptionType, newValue as ListOriginType)
							}
							options={[
								{ value: ListOriginType.Custom, label: 'Custom' },
								{ value: ListOriginType.DatabaseColumn, label: 'Database Column' },
							]}
						/>
					)
				}}
			/>
			<Box>
				{_isString(options) ? (
					<Box my={2}>
						<Controller
							control={control}
							name="options"
							render={({ field: { value, onChange } }) => (
								<Select
									options={[...mappedColumns.keys()].map((_columnKey) => ({
										_id: _columnKey,
										label: mappedColumns.get(_columnKey)?.name ?? '',
									}))}
									value={value}
									onChange={onChange}
									containerProps={{ mb: 2 }}
									label="Column"
								/>
							)}
						/>
						{columnHasDuplicatedValues && (
							<Alert severity="warning">This column has duplicated values</Alert>
						)}
					</Box>
				) : (
					<>
						{options.map((item, index) => {
							return (
								<Box key={item._id}>
									<ChildItem
										index={index}
										onRemove={() => handleRemoveItem(item._id)}
										item={item}
									/>
								</Box>
							)
						})}
						<Button sx={{ my: 2, mt: 1 }} variant="outlined" size="small" onClick={handleNewItem}>
							Add an option
						</Button>
					</>
				)}

				<Controller
					name={'isMenu'}
					control={control}
					render={({ field: { onChange, value } }) => (
						<Toggle
							label="How many options can be chosen?"
							value={value}
							options={[
								{
									label: 'Only one',
									value: false,
								},
								{
									label: 'Multiple',
									value: true,
								},
							]}
							onChange={onChange}
							containerProps={{ mb: 1.5 }}
						/>
					)}
				/>
			</Box>
		</Box>
	)
}

export const ChildFields = React.memo(UndecoratedChildFields)
