/**
 * Cango Project Database View
 */

import React, { ComponentType } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { Box } from 'src/components'
import { CoreTable } from 'src/modules/tables/core-table'

dayjs.extend(relativeTime)

export const DatabaseTable: ComponentType = () => {
	return (
		<Box px={4}>
			<CoreTable rowReordering={false} />
		</Box>
	)
}
