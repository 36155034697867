import {
	persistStore,
	persistReducer,
	createMigrate,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	createTransform,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import * as Sentry from '@sentry/react'
import { Middleware } from 'redux'
import { configureStore, MiddlewareAPI, Dispatch, AnyAction } from '@reduxjs/toolkit'

import {
	actions as persistedFilesActions,
	FILES_STORAGE,
	UpdaterCb,
} from './modules/persisted-files'
import rootReducer from './root-reducer'
import { RootState, CangoReduxModuleName } from './types'
import { migrations } from './migrations'

// blacklist stagedFiles from CangoReduxModuleName.CangoPersistedFiles slice
const blacklistStagedFiles = createTransform(
	(state: RootState[CangoReduxModuleName.CangoPersistedFiles]) => {
		const newState: RootState[CangoReduxModuleName.CangoPersistedFiles] = {}
		Object.keys(state).forEach((key) => {
			newState[key] = {
				...(state as any)[key],
				stagedFiles: [],
			}
		})
		return newState
	},
	(state) => state,
	{ whitelist: [CangoReduxModuleName.CangoPersistedFiles] },
)

const persistedReducer = persistReducer(
	{
		key: 'root',
		storage,
		whitelist: [
			CangoReduxModuleName.CangoAuth,
			CangoReduxModuleName.CangoPersistedConfig,
			CangoReduxModuleName.CangoPersistedFiles,
		],
		transforms: [blacklistStagedFiles],
		version: 15,
		migrate: createMigrate(migrations, { debug: false }),
	},
	rootReducer,
)

const sentryReportingMiddleware: Middleware<any, RootState> = (store) => (next) => (action) => {
	try {
		return next(action)
	} catch (error) {
		Sentry.withScope((scope) => {
			if (error instanceof Error) {
				scope.setExtra('reduxState', store.getState())
				scope.setExtra('action', action)
				Sentry.captureException(error)
			}
		})
		throw error
	}
}

const logger =
	(store: MiddlewareAPI<Dispatch<AnyAction>, any>) =>
	(next: Dispatch<AnyAction>) =>
	(action: any) => {
		/* eslint-disable no-console */
		console.group(action.type)
		console.info('dispatching', action)
		const result = next(action)
		console.log('next state', store.getState())
		console.groupEnd()
		/* eslint-enable no-console */
		return result
	}

export const BASE_URL = import.meta.env.VITE_API as string

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => {
		const defaultMiddleware = getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		})
		if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
			return defaultMiddleware.concat(logger)
		}
		return defaultMiddleware.concat(sentryReportingMiddleware)
	},
	devTools: import.meta.env.VITE_ENVIRONMENT === 'development',
})
export const persistor = persistStore(store)

function filesUpdater(parentFolderId: string, cb: UpdaterCb) {
	const storeState = store.getState() as RootState
	const persistedFilesState = storeState[CangoReduxModuleName.CangoPersistedFiles]
	const { stagedFiles, ...folderState } = persistedFilesState[parentFolderId]
	const newStagedFiles = cb(stagedFiles)
	store.dispatch(
		persistedFilesActions.setStagedFiles({
			id: parentFolderId,
			parentFolderName: folderState.parentFolderName,
			stagedFiles: newStagedFiles,
		}),
	)
}

FILES_STORAGE.setUpdater(filesUpdater)
