import dayjs from 'dayjs'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'

import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { Box, Button, Text, NameInput } from 'src/components'
import { AsyncDispatchType } from 'src/store/types'
import { colors } from 'src/theme/colors'
import { RouteId } from 'src/constants/routes'

export const ProjectSettings = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const navigate = useNavigate()
	const project = useSelector(projectSelectors.getSelectedProject)
	const [isDeletingProject, setIsDeletingProject] = useState(false)
	const [isArchivingProject, setIsArchivingProject] = useState(false)
	const projectName = project?.name || ''
	const isProjectArchived = !!project?.archived?.state

	const handleDeleteProjectClick = async () => {
		if (!project) return
		const confirmed = confirm(
			`Are you sure you want to delete ${projectName}? This action cannot be undone.`,
		)
		if (!confirmed) return

		setIsDeletingProject(true)
		await dispatch(projectActions.deleteProject({ projectId: project._id, navigate }))
		setIsDeletingProject(false)
	}

	const handleArchiveClick = async () => {
		if (!project) return
		const confirmed = confirm(
			`Are you sure you want to ${isProjectArchived ? 'restore' : 'archive'} ${projectName}?`,
		)
		if (!confirmed) return

		setIsArchivingProject(true)
		if (!isProjectArchived) {
			const response = await dispatch(
				projectActions.updateProject({
					projectId: project._id,
					update: {
						archived: {
							state: true,
							at: dayjs().unix(),
						},
					},
				}),
			)
			if (response.meta.requestStatus === 'fulfilled') {
				navigate(`/${RouteId.Projects}`)
			}
		} else {
			await dispatch(projectActions.restoreFromArchive({ projectId: project._id }))
		}
		setIsArchivingProject(false)
	}

	const handleUpdateName = async (newName: string) => {
		if (!project) return
		await dispatch(
			projectActions.updateProject({
				projectId: project._id,
				update: {
					name: newName,
				},
			}),
		)
	}

	return (
		<>
			<Box
				p={2}
				display="flex"
				flexDirection="column"
				boxShadow="rgb(0 0 0 / 12%) 2px 4px 24px;"
				borderRadius="0 20px 20px 20px"
				margin={4}>
				<Text variant="h5" mb={2}>
					Configuration
				</Text>
				<Grid container rowGap={[4, 6]} maxWidth="500px">
					<Grid item xs={12}>
						<Typography variant="overline" fontWeight="bold">
							Project Name
						</Typography>
						{project?.archived.state ? (
							<Typography variant="h6">{projectName}</Typography>
						) : (
							<NameInput name={projectName} onUpdateClick={handleUpdateName} />
						)}
					</Grid>

					<Grid item xs={12} marginTop={2}>
						<Box display="flex" flexDirection="column">
							<Typography variant="overline" fontWeight="bold" sx={{ color: colors.error.main }}>
								Danger Zone
							</Typography>
							<Box display="flex" gap={2}>
								<Button
									size="small"
									fullWidth
									onClick={handleArchiveClick}
									disabled={isArchivingProject || isDeletingProject}
									isLoading={isArchivingProject}
									variant="outlined">
									{isProjectArchived ? 'Restore project' : 'Archive project'}
								</Button>
								<Button
									fullWidth
									color="error"
									size="small"
									onClick={handleDeleteProjectClick}
									disabled={isArchivingProject || isDeletingProject}
									isLoading={isDeletingProject}
									variant="outlined">
									Delete Project
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
	)
}
